import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("edit-modal-composer", {
    attrs: {
      "has-deactivate-button": false,
      "title": "Edit Contract"
    }
  }, [_c("text-input", {
    key: "code",
    attrs: {
      "label": "Contract Code",
      "disabled": true,
      "span": 12
    }
  }), _c("text-input", {
    key: "growerCode",
    attrs: {
      "label": "Grower Code",
      "disabled": true,
      "span": 12
    }
  }), _c("text-input", {
    key: "growerName",
    attrs: {
      "label": "Grower Name",
      "placeholder": "",
      "disabled": true,
      "span": 12
    }
  }), _c("text-input", {
    key: "pricingCropCode",
    attrs: {
      "label": "Pricing Crop Code",
      "placeholder": "",
      "disabled": true,
      "span": 12
    }
  }), _c("switch-input", {
    key: "emailCertificates",
    attrs: {
      "label": "Email Certificates",
      "span": 12
    }
  }), _c("switch-input", {
    key: "emailPaddock",
    attrs: {
      "label": "Email Paddock",
      "span": 12
    }
  }), _c("text-input", {
    key: "emailAddress",
    attrs: {
      "label": "Email Address",
      "placeholder": "Type email address",
      "span": 24
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "EditContracts"
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var EditContracts = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "edit-contracts"
  }, [_c("resource", {
    attrs: {
      "name": "grower.contracts",
      "api-url": _vm.apiUrl,
      "edit": _vm.EditContracts,
      "redirect-route": "/watties-grower/contracts"
    }
  })], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  name: "EditContracts",
  data() {
    return {
      EditContracts,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
